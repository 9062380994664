'use client';
import { OnbModal } from '@/components/modal';
import OnbDevTools from '@/components/onbDevTools';
import { OnbToast } from '@/components/toast';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from '@repo/ui';
import { Provider } from 'react-redux';
import { store } from './store';

export function Providers({ children, nonce }: { children: React.ReactNode; nonce: string }) {
  return (
    <ThemeProvider theme={theme({ nonce })}>
      <CssBaseline />
      <Provider store={store}>
        <OnbModal />
        <OnbToast />
        {children}
        {process.env.NEXT_PUBLIC_ENABLE_DEVTOOLS && <OnbDevTools />}
      </Provider>
    </ThemeProvider>
  );
}
